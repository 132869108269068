/* App.css */

:root {
  --header-height: 5rem; /* Adjust this value as needed */
  --footer-height: 100px; /* Adjust this value as needed */
}


.app-container {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 34px);
  
}

html, body {
  margin: 0;
  height: 100%;
  min-height: 100%;
  width: 100%;
}

body {
  display: flex;
  flex-direction: column;
}

.main-content {
  /* color: rgb(115, 136, 202); */
  font-family: sans-serif;
  /* width: 100%; */
  /* max-width: 100%; */
  /* margin-right: auto; */
  /* margin-left: auto; */
  /* outline: auto; */
  padding-top: var(--header-height);
  padding-bottom: var(--footer-height);
  /* to prevent text from going off screen */
  overflow: hidden; 
  -webkit-overflow-scrolling: touch;
  flex: auto;
}


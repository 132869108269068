/* Home.css */

.home {
  /* padding: 2rem; */
  /* background:linear-gradient(white, rgba(163, 255, 190, 0.2)); */
  border-radius: 10px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

@-webkit-keyframes slide {
  0% {
    left: 0%;
  }
  33.33% {
    left: 0%;
  }
  36.67% {
    left: -100%;
  }
  66.67% {
    left: -100%;
  }
  70% {
    left: -200%;
  }
  100% {
    left: -200%;
  }
}

@keyframes slide {
  0% {
    left: 0%;
  }
  33.33% {
    left: 0%;
  }
  36.67% {
    left: -100%;
  }
  66.67% {
    left: -100%;
  }
  70% {
    left: -200%;
  }
  100% {
    left: -200%;
  }
}
.slider {
  font-family: Verdana, Geneva, sans-serif;
  overflow: hidden;
  width: 100%;
}
.slider__container {
  position: relative;
  -webkit-animation: 15s slide infinite;
  animation: 15s slide infinite;
  /* font-size: 0; */
  width: 300%;
}
.slider__container img {
  max-height: 100vh;
  position: relative;
  /* width: 100%; */
  display: block; /* Remove extra space below images */
}
.slider__container figure {
  display: inline-block;
  width: 33.33%;
}
.slider__container figcaption {
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.1);
  font-size: 32px;
  color: #fff;
  padding: 10px 16px;
  text-shadow: 2px 2px 6px #222;
  width: 100%;
}

.slider__content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  width: 100%;
  z-index: 1; /* Ensures content is above the images */
}

.slider__content h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.slider__content p {
  font-size: 1.5rem;
}

@media (min-width: 1024px) and (max-width: 1366px) {
  .slider__container {
    height: 90vh;
  }
  .slider__content {
    top: 75%;
  }
}

/* Mobile View Adjustments */
@media screen and (max-width: 768px) {
  /* Adjust the max-width value as needed */
  .slider {
    height: 400px; /* Adjust height for mobile view */
  }

  .slider__content h1 {
    font-size: 2.5rem; /* Adjust font size for smaller screens */
  }

  .slider__content p {
    font-size: 1.2rem; /* Adjust font size for smaller screens */
  }
}

.hero {
  text-align: center;
  /* padding: 4rem 1rem; */
  background-color: transparent;
  /* margin-bottom: 2rem; */
  /* background: url("../../assets/leaf4.jpeg") no-repeat; */
}

.hero-inner {
  padding-top: 17%;
  padding-bottom: 17.4%;
}

.hero h1 {
  font-size: 2.9rem;
  color: white;
}

.hero p {
  font-size: 1.7rem;
  color: #dddddd;
}

.services {
  /* margin-bottom: 2rem; */
  background: url("../../assets/services.jpg") repeat fixed;
  background-size: cover;
  padding: 5%;
}

.products {
  padding: 5%;
  display: block;
  justify-content: center;
  /* margin-bottom: 2rem; */
  /* border-top: 2px solid rgb(0, 30, 128);
  border-bottom: 2px solid rgb(0, 30, 128); */
  /* background-color: rgb(133, 20, 75, 0.1); */
  /* background:linear-gradient(white, rgba(163, 255, 190, 0.4)); */
}

.products h2 {
  font-size: 2rem;
  color: black;
  max-width: fit-content;
  padding-top: 1%;
  padding-bottom: 1%;
  padding-left: 3%;
  padding-right: 3%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
  background-color: hsl(138, 41%, 58%);
  border-radius: 10px;
}
.services h2 {
  font-size: 2rem;
  color: white;
  margin-bottom: 1rem;
}

.product-list,
.service-list {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.product-item,
.service-item {
  /* background-color: rgb(221, 221, 221, 0.6); */
  /* background-color: black; */
  /* border: 3px solid black; */
  /* opacity: 0.6; */
  padding: 1rem;
  /* border: 1px solid #ddd; */
  border-radius: 5px;
}

.product-item h3 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 0.5rem;
}
.service-item h3 {
  font-size: 1.5rem;
  color: white;
  margin-bottom: 0.5rem;
}

.product-item p {
  font-size: 1rem;
  color: #666;
}
.service-item p {
  font-size: 1rem;
  color: white;
}

.soon-span {
  color: rgb(255, 255, 255);
  font-size: 14px;
}

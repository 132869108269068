@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&family=Montserrat:wght@400;600;700&display=swap");
@import url('https://fonts.cdnfonts.com/css/bebas-neue');

* {
 box-sizing: border-box;
 padding: 0;
 margin: 0;
}

:root {
 --header-height: 3.5rem;
 --first-color: hsl(138, 41%, 58%);
 --title-color: hsl(0, 0%, 0%);
 --text-color: hsl(0, 0%, 0%);
 --body-color: hsl(0, 0%, 100%);
 --body-font: "Montserrat", sans-serif;
 --second-font: 'Bebas Neue', sans-serif;
 --h2-font-size: 1.25rem;
 --small-font-size: 0.813rem;
 /* --small-font-size: 0.813rem; */
 --font-semi-bold: 600;
 --z-tooltip: 10;
 --z-fixed: 100;
 
}


body {
 color: var(--text-color);
 font-family: var(--body-font);
 background-color: hsl(0, 0%, 100%);
 /* background-image: url("assets/gradiant.jpg"); */
 /* background-repeat: no-repeat;
 background-size: cover; */


}

ul {
 list-style: none;
}

a {
 text-decoration: none;
}
/* Define the height of the header */
:root {
  --header-height: 5rem; /* Adjust this value as needed */
}

.header {
  position: initial;
  right: 0;
  left: 0;
  z-index: 1030;
  width: 100%;
  top: 0;
  left: 0;
  background-color: transparent;
  z-index: var(--z-fixed);
  height: var(--header-height);
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: var(--header-height);
  margin: 1rem;
  padding-right: 20px;
  padding-left: 20px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  
}

.nav__logo img {
  /* height: 50%; */
  width: 50%;
  white-space: nowrap;
  vertical-align: middle;
}

.logo-span {
    color: hsl(138, 41%, 58%);
}

.nav__toggle,
.nav__close {
  font-size: 1.5rem;
  color: var(--title-color);
  cursor: pointer;
}

.nav__cta {
  background-color: var(--first-color);
  color: var(--title-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.5s ease;
  padding: 0.75rem 0.75rem;
  min-width: 100px;
}
.nav__cta:hover {
  background-color: black;
  transition: background-color 0.5s ease;
}

.nav__list {
  display: flex;
  flex-direction: column;
  row-gap: 2.5rem;
  white-space: nowrap;
}

.nav__link {
  color: var(--title-color);
  font-weight: var(--font-semi-bold);
  transition: color 0.4s;
  position: relative;
  font-size: 17px;
}

.nav__link:hover {
  color: hsl(138, 41%, 58%);
}

.nav__close {
  position: absolute;
  top: 1rem;
  right: 1.5rem;
}

.show-menu {
  right: 0;
}

.dropdown-menu {
  display: none;
  position: absolute;
  background-color: rgb(248, 248, 248);
  /* box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); */
  padding: 10px 30px;
  /* text-align: left; */
  border-radius: 4px;
  /* min-width: 150px; */

  width: max-content;
  opacity: 0.7;
  /* list-style-type: none; */
  /* z-index: 1000; */
}

.dropdown-menu .nav__link {
  padding: 4px;
  /* margin: 3px; */
  line-height: 30px;
  border-radius: 5px;
}

.dropdown-menu .nav__link:hover {
  background-color: black;
  color: white;
}

.show-dropdown {
  display: block;
}
@media (min-width: 769px) and (max-width: 1024px) {
  .nav__logo img {
    width: 43%;
  }
}

@media (min-width: 1024px) and (max-width: 1366px) {
  .nav__logo img {
    width: 30%;
  }
}

@media screen and (max-width: 1150px) {
  .nav__menu {
    position: fixed;
    top: 0;
    right: -100%;
    background-color: hsla(140, 100%, 85%, 0.473);
    backdrop-filter: blur(16px);
    -webkit-backdrop-filter: blur(16px);
    width: 80%;
    height: 100%;
    padding: 6rem 3rem 0;
    transition: right 0.4s;
  }

  .nav__link {
    color: black;
  }
  .nav__link:hover {
    color: rgb(255, 255, 255);
  }

  .nav__menu.show-menu {
    right: 0;
  }

  .dropdown-menu {
    position: static;
    box-shadow: none;
    white-space: inherit;
  }
  .nav__logo {
    width: 100%
  }
  .nav__cta {
    background-color: rgba(78, 175, 255, 0.9);
  }
}

@media screen and (min-width: 1150px) {
  .nav {
    height: calc(var(--header-height) + 1rem);
  }

  .nav__toggle,
  .nav__close {
    display: none;
  }

  .nav__list {
    flex-direction: row;
    column-gap: 2.5rem;
  }

  .dropdown:hover .dropdown-menu {
    display: block;
    padding: 15px;
  }
}

@media (min-width: 1024px) and (max-width: 1366px) {
  .nav__logo img {
    width: 60%;
    vertical-align: middle;
  }
  
}

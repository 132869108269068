/* About.css */

.about {
  padding: 2rem;
  width: fit-content;
  margin-right: auto;
  margin-left: auto;
  /* text-align: center; */
  /* background:linear-gradient(white, rgba(78, 255, 187, 0.2)); */
  
}

.about-section {
  margin-bottom: 2rem;  
}

.about-section h1 {
  font-size: 2.8rem;
  color: #333;
  margin-bottom: 1rem;
}

.about-section p, .mission-section p {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased !important;
  -moz-font-smoothing: antialiased !important;
  text-rendering: optimizelegibility !important;
  letter-spacing: .03em;
  font-size: 1.25rem;
  color: #666;
  margin-bottom: 1rem;
  /* background-color: rgb(104, 192, 130, 0.4); */
  padding: 20px;
  border-radius: 10px;
}

.values-section h2, .mission-section h2  {
  font-size: 3rem;
  color: #333;
  margin-bottom: 1rem;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}

.values-section ul {
  list-style-type: none;
  padding: 0;
  /* background-color: rgb(104, 192, 130, 0.4); */
  padding: 20px;
  border-radius: 10px;
}

.values-section li {
  font-size: 1.25rem;
  color: #666;
  margin-bottom: 0.5rem;
}

.about-icon {
  height: 50px;
  width: 50px;
  vertical-align: middle;
}
.check-icon {
  height: 70px;
  width: 70px;
  vertical-align: middle;
}
.mission-badge {
  width: 300px;
  border-radius: 40px;
  vertical-align: middle;
}

.values-badge {
  width: 250px;
  border-radius: 30px;
  vertical-align: middle;
}

.about-flex {
  display: flex;
  justify-content: space-between;
}

/* Mobile View Adjustments */
@media screen and (max-width: 768px) { /* Adjust the max-width value as needed */
    .about-flex {
    display: contents;
  }

  .values-section h2, .mission-section h2  {
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}
}

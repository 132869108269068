:root {
  --footer-height: 80px; /* Adjust this value as needed */
}

.footer {
  position:relative;
  bottom: 0;
  width: 100%;
  height: var(--footer-height);
  background-color: hsl(210, 8%, 5%);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.footer__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.footer__nav {
  margin-top: 0.5rem;
  display: flex;
  gap: 1rem;
}

.anchor {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s;
}

.footer__nav a:hover {
  color: hsl(138, 41%, 58%);
}

@media screen and (min-width: 768px) {
  .footer__content {
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
  }

  .footer__nav {
    margin-top: 0;
  }
}

/* Contact.css */

.contact {
  padding: 2rem;
  width: fit-content;
  margin-right: auto;
  margin-left: auto;
  /* text-align: center; */
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 4px;
  /* border: 2px solid black; */
  background-color: rgba(0, 113, 206, 0.02);
}

.contact-icon {
  height: 30px;
  width: 30px;
  align-items: center;
  text-align: center;
  vertical-align: middle;
}

.contact-info {
  margin-bottom: 2rem;
  /* border: 1px solid black; */
  border-radius: 10px;
  padding: 4%;
  background-color: rgba(0, 247, 95, 0.08);
}

.contact-info h1 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 1rem;
}

.contact-info p {
  font-size: 1.25rem;
  color: #666;
  margin-bottom: 0.5rem;
}

.contact-info a {
  color: #007BFF;
  text-decoration: none;
}

.contact-info a:hover {
  text-decoration: underline;
}

.contact-form {
  margin-top: 2rem;
  width: 30%;
}

.contact-form h2 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 1rem;
}

.contact-form p {
  font-size: 1.25rem;
  color: #666;
  margin-bottom: 1.5rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  font-size: 1rem;
  color: #333;
  display: block;
  margin-bottom: 0.5rem;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #007BFF;
}

.submit-button {
  background-color: rgba(0, 161, 62, 0.6);
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #0056b3;
}


@media (max-width: 1024px) {
  .contact {
    display: grid;
  }
  .contact-form {
    width: 100%;
  }
  .contact-info p {
    font-size: 18px;
  }
}

@media (min-width: 1024px) and (max-width: 1366px) {
  .contact {
    margin-left: 50px;
    margin-right: 50px;
  }
  .contact-form textarea {
    height: 4em;
  }
  
}

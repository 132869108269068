.soon-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70vh;
  background: linear-gradient(135deg, #ffffff, #f0f0f0);
  text-align: center;
}

.soon-title {
  font-size: 3rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 1rem;
}

.soon-subtitle {
  font-size: 1.5rem;
  color: #666;
  margin-bottom: 2rem;
}

.soon-button {
  padding: 0.75rem 2rem;
  font-size: 1.25rem;
  font-weight: bold;
  color: white;
  background-color: #007BFF;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.soon-button:hover {
  background-color: #0056b3;
}
